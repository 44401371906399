@value desktop-s, desktop-m, tablet from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media desktop-s {
    row-gap: 48px;
  }
}

.buttonWrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 343px;
  height: 68px;
}
